.multiselect {
  min-height: $select-height;
  font-family: $font-family-primary;

  &--active > &__tags {
    border-radius: $size-xs !important;
    box-shadow: inset 0 2px 4px -1px rgba(9, 30, 66, .15), 0 0 0 1.6px $color-primary;
    transition: all 150ms ease-in-out;
  }

  &--active > &__select {
    transform: rotate(0);
  }

  &__input,
  &__single {
    padding: 0;
    margin: 0;
    font-weight: $font-weight-medium;
    color: $color-ink;
    background: none;
    caret-color: $color-primary;
  }

  &__input::placeholder {
    color: $color-ink-lighter;
  }

  &__tags {
    display: flex;
    align-items: center;
    min-height: $select-height;
    padding: 0 $size-s;
    padding-right: $size-xl;
    cursor: pointer;
    background: linear-gradient(0deg, rgba($color-black, .12) 0%, transparent 50%), $color-white;
    border: 1px solid $color-ink-lighter;
    border-radius: $size-xs;
  }

  &__placeholder {
    padding: 0;
    margin: 0;
    font-weight: $font-weight-medium;
    color: $color-ink-lighter;
  }

  &__select {
    top: 0;
    right: 0;
    width: $select-height;
    height: 100%;

    &::before {
      position: absolute;
      top: 8px;
      right: 8px;
      display: block;
      width: $size-m;
      height: $size-m;
      margin-top: 2px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12' height='8'%3E%3Cuse fill='%23B3B5BA' transform='translate%281 1.5%29' xlink:href='%23path0_stroke'/%3E%3Cdefs%3E%3Cpath id='path0_stroke' d='M.7-.7A1 1 0 0 0-.7.7L.7-.7zM5 5l-.7.7a1 1 0 0 0 1.4 0L5 5zM10.7.7A1 1 0 0 0 9.3-.7L10.7.7zM-.7.7l5 5 1.4-1.4-5-5L-.7.7zm6.4 5l5-5L9.3-.7l-5 5 1.4 1.4z'/%3E%3C/defs%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      border: none;
    }
  }

  &__content-wrapper {
    border-color: $color-ink-lighter;
    border-radius: $size-xs;
    box-shadow: 0 10px $size-s -2px rgba($color-ink, .6);
    transform: translateY(4px);
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: $size-xl;
    padding: 0 $size-s;
    border-bottom: 1px solid $color-ice;

    &--highlight {
      background: $color-primary;

      &::after {
        content: "";
        background: $color-primary;
      }
    }

    &--selected {
      background: $color-ice;

      &::after {
        top: 14px;
        right: 14px;
        left: auto !important;
        width: $size-m;
        height: $size-m;
        padding: 0;
        margin: 0;
        content: "";
        background: {
          image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath stroke='%2378809A' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M17 1.8l-11 11-5-5'/%3E%3C/svg%3E");
          repeat: no-repeat;
          position: center center;
        }
      }
    }
  }

  .multiselect__option--selected.multiselect__option--highlight {
    background: $color-danger;

    &::after {
      top: 14px;
      right: 14px;
      left: auto !important;
      width: $size-m;
      height: $size-m;
      content: "";
      background: {
        image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none'%3E%3Cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 1.9l-12 12M1 1.9l12 12'/%3E%3C/svg%3E");
        repeat: no-repeat !important;
        position: center center !important;
      }
    }
  }
}
