input[type="radio"] {
  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0;
  border: 1.5px solid $color-ink-lighter;
  border-radius: 50%;
  transition: all $speed-x-fast ease-in-out;
  appearance: none;

  &:focus {
    outline: 0;
    box-shadow: 0 0 1px 1.5px #2789ff;
    transition: all $speed-x-fast ease-in-out;
  }

  &:hover {
    border-color: $color-ink-light;
    transition: all $speed-x-fast ease-in-out;
  }

  &:checked {
    background-color: $color-primary;
    border-color: $color-primary;
    transition: all $speed-x-fast ease-in-out;

    &::before {
      opacity: 1;
      transition: all $speed-fast ease-in-out;
      transform: scale(1);
    }
  }

  &::before {
    width: 60%;
    height: 60%;
    content: "";
    background: $color-white;
    border-radius: 50%;
    opacity: 0;
    transition: all $speed-fast ease-in-out;
  }
}
